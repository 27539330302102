import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "./Authcontext";

import {
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";


const Side2 = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const [open, setOpen] = useState(false);
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const toggleLanguage = () => {
    const newLang = language === "en" ? "ar" : "en"; // إذا كانت إنجليزية نبدل إلى عربية والعكس
    setLanguage(newLang);
    i18n.changeLanguage(newLang); // تغيير اللغة في i18n
  };
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  // Handle logout functionality
  const handleLogout = () => {
    logout(); // Call the logout function to clear the token and local storage
    console.log("Logout"); // Optional: Log out message
    navigate("/login"); // Navigate to the login page after logging out
  };

  const drawer = (
    <div
      style={{
        backgroundColor: "#202124", // خلفية الـ Sidebar
        color: "#ff8800", // لون النص داخل الـ Sidebar
        width: "100%", // عرض الـ Sidebar
        height: "100%", // جعل الارتفاع كامل
        padding: "0px", // إضافة padding للتباعد
      }}
    >
      <div style={{display : "flex" ,flexDirection : "row" , gap : "10px" , alignItems : "center"}}>
        <img src={require("../image/ei logo1.png")} height={"40px"} alt="" />
      <h1>Elite investing</h1>
      </div> {/* لون العنوان */}
      <List>
        <ListItem button component={Link} to="/home">
          <img src={require("../image/home.png")} height={"30px"} alt="home" />
          <ListItemText
            primary="Home"
            sx={{
              textDecoration: "none", // إزالة التزيين النصي
              color: "#ff8800",
              marginLeft: "10px", // لون النص
            }}
          />
        </ListItem>

        <ListItem button component={Link} to="/advantages">
          <img
            src={require("../image/recovery.png")}
            height={"30px"}
            alt="advantages"
          />
          <ListItemText
            primary="Advantages"
            sx={{
              textDecoration: "none", // إزالة التزيين النصي
              color: "#ff8800",
              marginLeft: "10px", // لون النص
            }}
          />
        </ListItem>

        <ListItem button component={Link} to="/withdraws">
          <img
            src={require("../image/withdraw.png")}
            height={"30px"}
            alt="withdraws"
          />
          <ListItemText
            primary="Withdraws"
            sx={{
              textDecoration: "none", // إزالة التزيين النصي
              color: "#ff8800",
              marginLeft: "10px", // لون النص
            }}
          />
        </ListItem>

        <ListItem button component={Link} to="/levels">
          <img
            src={require("../image/left-alignment.png")}
            height={"30px"}
            alt="profile"
          />
          <ListItemText
            primary="Levels"
            sx={{
              textDecoration: "none", // إزالة التزيين النصي
              color: "#ff8800",
              marginLeft: "10px", // لون النص
            }}
          />
        </ListItem>
        <ListItem button component={Link} to="/charge">
          <img
            src={require("../image/money_14957415.png")}
            height={"30px"}
            alt="profile"
          />
          <ListItemText
            primary="Charge"
            sx={{
              textDecoration: "none", // إزالة التزيين النصي
              color: "#ff8800",
              marginLeft: "10px", // لون النص
            }}
          />
        </ListItem>
      </List>
      <Button
        onClick={handleLogout}
        sx={{
          backgroundColor: "#ff4040", // Red background for the logout button
          color: "white",
          marginTop: "20px",
          width: "100%",
          padding: "10px",
          fontSize: "16px",
          textAlign: "center",
          borderRadius: "5px",
          "&:hover": {
            backgroundColor: "#e03333", // Darker red on hover
          },
        }}
      >
        Logout
      </Button>
      <div style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
        <Button
          onClick={toggleLanguage}
          sx={{
            backgroundColor: "#ff8800",
            color: "white",
            "&:hover": {
              backgroundColor: "#b07027",
            },
          }}
        >
          {i18n.language === "en" ? "ar" : "en"}
        </Button>
      </div>
    </div>
  );

  return (
    <div style={{ height: "60px" }}>
      <AppBar
        position="static"
        sx={{
          height: "60px",
          width: "100%",
          backgroundColor: "#202124", // خلفية شريط التطبيقات
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            sx={{ color: "#ff8800", width: "10%", marginLeft: "10px" }} // لون الأيقونة
            onClick={handleDrawerToggle}
          >
            <MenuIcon sx={{ color: "#ff8800" }} /> {/* لون الأيقونة */}
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left" // جعل الـ Sidebar في أقصى اليسار
        open={open}
        onClose={handleDrawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: "#202124", // خلفية الـ Drawer
            color: "#ff8800", // لون النص داخل الـ Drawer
            width: "270px", // عرض الـ Sidebar
            padding: "10px", // إضافة padding للتباعد
          },
        }}
      >
        {drawer}
      </Drawer>
    </div>
  );
};

export default Side2;
