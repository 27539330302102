import React from "react";
import { useParams } from "react-router-dom";

const Detail = () => {
  const { id } = useParams(); // الحصول على المعرف من الـ URL
  const Data = [
    {
      id: 1,
      img: require("../image/location.png"),
      title: "Our location: Britain",
      description:
        "We are located in Britain and have a wide presence across multiple regions.",
    },
    {
      id: 2,
      img: require("../image/bank.png"),
      title: "Year of establishment: 2019",
      description: `This company was established in 2019, marking the beginning of a new journey filled with challenges and ambitions.
        Since its inception, it has aimed to achieve its goals and offer exceptional services or products that meet market needs and keep up with the latest developments.
        Over time, it has managed to build a strong fan base and achieve significant successes in its field, becoming one of the leading names in its industry.
        The year 2019 served as a launchpad for a bright and evolving future.`,
    },
    {
      id: 3,
      img: require("../image/group.png"),
      title: "+100000 Users",
      description: `Since its launch in 2019, the company has rapidly grown and now boasts over 100,000 users. 
This achievement is a testament to the quality of its products and services, which have resonated with a wide audience.
 The company's continuous commitment to innovation and customer satisfaction has allowed it to build a strong and loyal user base. 
As it moves forward, the company is dedicated to expanding even further,
 aiming to reach new milestones and provide even greater value to its ever-growing community`,
    },
    {
      id: 4,
      img: require("../image/pointer.png"),
      title: "Spread in more than one country",
      description: `The company has achieved remarkable success since its establishment in 2019,
 reaching over 100,000 users. This success is not limited to just one market; 
the company has expanded to multiple countries, significantly growing its user base. 
With continuous expansion into new markets,
the company is committed to providing its exceptional services to users around the world`,
    },
    {
      id: 5,
      img: require("../image/mining_898304.png"),
      title: "Cryptocurrency mining",
      description: `Cryptocurrency mining is the process of validating and securing transactions on a blockchain network, while simultaneously introducing new coins into circulation. This process involves solving complex cryptographic puzzles using specialized hardware, and miners are rewarded with cryptocurrency for their efforts. It plays a crucial role in maintaining the decentralized nature of blockchain networks and ensuring their integrity.`,
    },
    {
      id: 6,
      img: require("../image/contract_9196743.png"),
      title: "Speculative contracts",
      description: `Speculative contracts are financial agreements where investors engage in trades based on predictions about the future price of an asset or commodity. These contracts allow traders to speculate on price movements without owning the underlying asset. Speculative contracts are often used to capitalize on market volatility, and they carry high risks as the value can fluctuate dramatically depending on market conditions.`,
    },
    {
      id: 7,
      img: require("../image/oil-platform_2082691.png"),
      title: "Oil contracts",
      description: `Oil contracts are agreements between buyers and sellers that involve the purchase or sale of crude oil at a predetermined price for future delivery. These contracts allow investors, producers, and traders to hedge against price fluctuations or to speculate on the future price of oil. Oil contracts can be used to manage risk or profit from changes in supply, demand, geopolitical events, and market trends within the energy sector.`,
    },
    {
      id: 8,
      img: require("../image/earning_5501360.png"),
      title: "Investments",
      description: `Investments refer to the allocation of money or resources into various assets, ventures, or opportunities with the expectation of generating future returns. These can include stocks, bonds, real estate, startups, and other financial instruments. By diversifying investments, individuals and organizations aim to grow their wealth over time while managing risks. The goal is to achieve long-term financial growth, income generation, or capital appreciation through strategic decisions and market participation.`,
    },
  ];
  // العثور على العنصر بناءً على المعرف
  const item = Data.find((d) => d.id === parseInt(id));

  return (
    <div className="Detail">
      {item ? (
        <div className="item22">
          <div>
            <img src={item.img} alt={item.title} height="200px" width="200px" />
          </div>
          <div className="textcontt">
            <h1>{item.title}</h1>
            <p>{item.description}</p>
          </div>
        </div>
      ) : (
        <p>Item not found</p>
      )}
    </div>
  );
};

export default Detail;
