import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { AuthContext } from "./Authcontext";
import Loader from "./Loader";
import { API_URL } from "../confing";
import { Chip } from "@mui/material"; // استيراد Chip من ماتريال UI
import { useTranslation } from "react-i18next";

const TableofWithdraws = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();
  const fetchwithdraws = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/users/withdraws/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Full Response:", response);

      const data = response.data.data; // تأكد أن `data` هو مصفوفة

      if (!Array.isArray(data)) {
        console.error("Expected data to be an array, but it's not:", data);
        return;
      }

      // تحويل البيانات لعرضها في الجدول
      const rowsData = data.map((item) => {
        const [date, time] = item.date.split("T");
        return {
          id: item.id, 
          amount: item.amount,
          date, 
          time,
          status: item.status, // إضافة status هنا
        };
      });

      setRows(rowsData); // تعيين الصفوف
    } catch (error) {
      console.error("Error fetching withdraws:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchwithdraws();
  }, [token]);

  // الأعمدة التي سيتم عرضها في الجدول
  const columns = [
    { field: "amount", headerName: `${t("ammount")}`, headerClassName: 'super-app-theme--header', flex: 0.3, editable: false },
    { field: "date", headerName: `${t('date')}`, headerClassName: 'super-app-theme--header', flex: 0.3, editable: false },
    { 
      field: "status", 
      headerName: `${t('status')}`, 
      headerClassName: 'super-app-theme--header', 
      flex: 0.3, 
      editable: false,
      renderCell: (params) => {
        // عرض status كـ Chip مفرغ
        return (
          <Chip
            label={params.value} // قيمة الـ status
            style={{
              backgroundColor: "transparent", // خلفية شفافة
              border: "1px solid #ff8000", // إضافة حد باللون المطلوب
              color: '#ff8000',
              borderRadius :"8px",
              marginBottom: "auto" // النص باللون المطلوب
            }}
          />
        );
      }
    },
  ];

  if (loading) return <Loader />;

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "95%",
          textAlign: "center",
          overflow: "hidden",
          backgroundColor: "#202124",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Box sx={{ width: "100%", overflowX: "auto", backgroundColor: "#202124" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 9,
                },
              },
            }}
            pageSizeOptions={[9]}
            checkboxSelection
            disableRowSelectionOnClick
            sx={{
              minWidth: "800px",
              width: "100%",
              border: "none",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#202124 !important",
                color: "#ff8800 !important",
                fontSize: "15px",
                fontWeight: "500",
              },
              "& .MuiDataGrid-row": {
                backgroundColor: "#202124",
                color: "#ff8800",
                "&:hover": {
                  backgroundColor: "#F0F0F0",
                },
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "1px solid #202124",
              },
              "& .MuiCheckbox-root": {
                color: "#ff8800",
              },
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                backgroundColor: "#202124 !important",
                color: "ff8800",
              },
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "20px",
              },
              "& .super-app-theme--header": {
                backgroundColor: "#202124 !important",
                color: "ff8800",
              },
              "& .MuiDataGrid-cell, .MuiDataGrid-columnHeader": {
                outline: "none",
              },
              "@media (max-width: 600px)": {
                ".MuiDataGrid-columnHeaders": {
                  fontSize: "13px",
                },
                ".MuiDataGrid-cell": {
                  fontSize: "12px",
                  padding: "8px",
                },
              },
            }}
            loading={loading}
          />
        </Box>
      </Box>
    </div>
  );
};

export default TableofWithdraws;
