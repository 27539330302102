import React from "react";
import Advantages from "./Advantages";
import "../css/Advantages.css";
import SendWith from "./SendWith";
import { useTranslation } from "react-i18next";
import TableofWithdraws from "./TableofWithdraws";
import TableofCharge from "./TableofCharge";
const Withdraws = () => {

  const { t } = useTranslation();
  return (
    <div
      className="UserTab"
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <section className="table">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems : "center",
            padding:"15px"
          }}
        >
          <h1 className="title">Charge:</h1>
        </div>
        <TableofCharge/>
      </section>
    </div>
  );
};

export default Withdraws;