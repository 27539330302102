import React from "react";
import { Box } from "@mui/material";
import Lottie from "lottie-react";
import Loader1 from "../Loader.json";

const Loader = () => (
  <Box
    sx={{
      position: "fixed", // لجعل اللودر ثابتًا في الشاشة
      top: "50%", // توسيط عمودي
      left: "50%", // توسيط أفقي
      transform: "translate(-50%, -50%)", // تعديل الموضع ليكون في المنتصف
      // تأكد من أن اللودر فوق كل شيء
      width: "200px", // عرض اللودر
      height: "200px", // ارتفاع اللودر
    }}
  >
    <Lottie animationData={Loader1} loop={true} autoplay={true} />
  </Box>
);

export default Loader;