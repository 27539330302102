import React from 'react'
import Advantages from './Advantages'
import '../css/Advantages.css'
import { useTranslation } from "react-i18next";

const AdvantagesTable = () => {
  const { t } = useTranslation();
  return (
    <div
      className="UserTab"
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <section className="table">
        <h1 className="title">{t('advantages')} :</h1>
        <Advantages/>
      </section>
    </div>
  )
}

export default AdvantagesTable