import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationAR from './locales/ar/translation.json';

const resources = {
  en: {
    translation: translationEN
  },
  ar: {
    translation: translationAR
  }
};

// الحصول على لغة الجهاز الافتراضية
const userLang = (navigator.language || navigator.userLanguage).split('-')[0];;

i18n
  .use(initReactI18next) 
  .init({
    resources,
    lng: userLang, // استخدام لغة الجهاز الافتراضية هنا
    fallbackLng: 'en', // اللغة الاحتياطية إذا لم تتوفر الترجمة
    interpolation: {
      escapeValue: false 
    }
  });

export default i18n;