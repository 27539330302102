import React from "react";
import { CircularProgress, Box, Typography } from "@mui/material";

const CircularProgressWithLabel = ({ value }) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
      }}
    >
      {/* دائرة التقدم */}
      <CircularProgress
        variant="determinate"
        value={value}
        size={150}
        thickness={5}
        sx={{
          color: "#FF8F14", // اللون الأساسي للدائرة
          '& .MuiCircularProgress-circle': {
            strokeLinecap: 'round', // الحواف الناعمة
          },
        }}
      />
      {/* النص في المنتصف */}
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontWeight: "bold",
            fontSize: "1.2rem",
            color: "#ff8f14",
          }}
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel