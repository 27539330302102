import React from "react";

const Location = () => {
  return (
    <div className="location-container">
      <div className="location-content">
        <div className="map-container">
          <iframe
            title="Google Maps of London"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10499.27729173248!2d-0.12775806546036985!3d51.50735098097514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761cb1f5c8bfab%3A0x79a3137a4bb5e2e7!2sLondon%2C%20UK!5e0!3m2!1sen!2sus!4v1733499739192!5m2!1sen!2sus"
            width="100%"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="text-container">
          <h1>Our Location:</h1>
          <p>
            We are proud to be based in London, a vibrant and dynamic city at
            the heart of the United Kingdom. Known for its iconic landmarks,
            diverse culture, and strong economic influence, London is a hub for
            innovation and business. Whether you're exploring the historic
            streets of the City of Westminster or engaging with the thriving
            tech scene in East London, we are strategically located to serve our
            customers with excellence and efficiency.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Location;
