import "../css/side.css";
import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "./Authcontext";
import Side2 from "./Side2";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const SideBar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const toggleLanguage = () => {
    const newLang = language === "en" ? "ar" : "en"; // إذا كانت إنجليزية نبدل إلى عربية والعكس
    setLanguage(newLang);
    i18n.changeLanguage(newLang); // تغيير اللغة في i18n
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  if (isMobile) {
    return <Side2 />;
  }

  return (
    <div className="sidebar">
      <div style={{display : "flex" ,flexDirection : "row" , gap : "10px" ,  alignItems : "center"}}>
        <img src={require("../image/ei logo1.png")} height={"40px"} alt="" />
      <h1>Elite investing</h1>
      </div>
      <Link to="/home">
        <div className="link">
          <img src={require("../image/home.png")} height={"30px"} alt="home" />
          <p style={{ textDecoration: "none", color: "#ff8800" }}>
            {t("home")}
          </p>
        </div>
      </Link>
      <Link to="/advantages">
        <div className="link">
          <img src={require("../image/recovery.png")} height={"30px"} alt="advantages" />
          <p style={{ textDecoration: "none", color: "#ff8800" }}>
            {t("advantages")}
          </p>
        </div>
      </Link>
      <Link to="/withdraws">
        <div className="link">
          <img src={require("../image/withdraw.png")} height={"30px"} alt="withdraws" />
          <p style={{ textDecoration: "none", color: "#ff8800" }}>
            {t("withdraws")}
          </p>
        </div>
      </Link>
      <Link to="/levels">
        <div className="link">
          <img src={require("../image/left-alignment.png")} height={"30px"} alt="levels" />
          <p style={{ textDecoration: "none", color: "#ff8800" }}>
            {t("levels")}
          </p>
        </div>
      </Link>
      <Link to="/charge">
        <div className="link">
          <img src={require("../image/money_14957415.png")} height={"30px"} alt="levels" />
          <p style={{ textDecoration: "none", color: "#ff8800" }}>
            Charge
          </p>
        </div>
      </Link>
      <Button
        onClick={handleLogout}
        sx={{
          backgroundColor: "#ff4040",
          color: "white",
          marginTop: "20px",
          width: "100%",
          padding: "10px",
          fontSize: "16px",
          textAlign: "center",
          borderRadius: "5px",
          "&:hover": {
            backgroundColor: "#e03333",
          },
        }}
      >
        {t("logout")}
      </Button>
      <div style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
        <Button
          onClick={toggleLanguage}
          sx={{
            backgroundColor: "#ff8800",
            color: "white",
            "&:hover": {
              backgroundColor: "#b07027",
            },
          }}
        >
          {i18n.language === "en" ? "ar" : "en"}
        </Button>
      </div>
    </div>
  );
};

export default SideBar;