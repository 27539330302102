/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useContext } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import axios from "axios";
import { API_URL } from "../confing";
import { AuthContext } from "./Authcontext";
import CircularProgress from "@mui/material/CircularProgress";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from "react-i18next";
import ErrorIcon from "@mui/icons-material/Error"; // استيراد أيقونة الخطأ

const SendAd = () => {
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [reference_number, setTransactionId] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [Data, setUserData] = useState(null);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setError(null);
  };

  const handleCopyToClipboard = () => {
    if (Data?.content) {
      navigator.clipboard.writeText(Data.content); // نسخ النص من المحتوى
      setSnackbarMessage(t("copySuccess")); // رسالة نجاح النسخ
      setSnackbarOpen(true);
    } else {
      setSnackbarMessage(t("noContentToCopy")); // رسالة إذا لم يوجد محتوى
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${API_URL}/api/users/statics/filter?name=wallet`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // المصادقة
              "Accept-Language": "en", // تحديد اللغة (افتراضيًا الإنجليزية)
            },
          }
        );
        setUserData(response.data.data);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(t("fetchError"));
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [token, t]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // التحقق من إدخال الرقم المرجعي
    if (!reference_number.trim()) {
      setSnackbarMessage(t("transactionIdRequired"));
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    try {
      await axios.post(
        `${API_URL}/api/users/charges`,
        { reference_number },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSnackbarMessage(t("submitSuccess"));
      setSnackbarOpen(true);
      setTransactionId("");
      handleClose();
    } catch (err) {
      console.error("Error submitting data:", err);
      setError(t("submitError"));
    } finally {
      setLoading(false);
    }
  };
  if (!token){
    setLoading (true)
  }

  return (
    <React.Fragment>
      <button onClick={handleClickOpen} className="mm">
        {t("addba")}
        <img src={require("../image/add (1).png")} height="30px" alt="" />
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { backgroundColor: "#202124", color: "#ff8800" },
        }}
      >
        <DialogTitle style={{ color: "#ff8800" }}>{t("addba")}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="readOnlyText"
            label={`${t("lable1")} :`}
            fullWidth
            variant="standard"
            InputLabelProps={{ style: { color: "#ff8800" } }}
            InputProps={{ style: { color: "#ff8800" }, readOnly: true }}
            value={Data?.content || t("noContentAvailable")}
          />
          <Button
            onClick={handleCopyToClipboard}
            startIcon={<ContentCopyIcon />}
            sx={{ color: "#ff8800", marginTop: "10px" }}
          >
            {t("copy")}
          </Button>
          <TextField
            required
            margin="dense"
            id="transactionId"
            name="transactionId"
            label={t("transactionHash")}
            type="text"
            fullWidth
            variant="standard"
            InputLabelProps={{ style: { color: "#ff8800" } }}
            InputProps={{ style: { color: "#ff8800" } }}
            value={reference_number}
            onChange={(e) => setTransactionId(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "#ff8800" }}>
            {t("cancel")}
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit}
            style={{ color: "#ff8800" }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} style={{ color: "#ff8800" }} />
            ) : (
              t("senddata")
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          severity={error ? "error" : "success"} // تغيير النوع حسب وجود الخطأ
          variant="filled"
          style={{
            backgroundColor: error ? "#d32f2f" : "#ff8800", // اللون الأحمر للخطأ
            color: "#fff",
          }}
          icon={<ErrorIcon style={{ color: "#fff" }} />} // إضافة أيقونة الخطأ
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default SendAd;
