import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from "chart.js";
import { Card, CardContent } from "@mui/material";

// تسجيل المكونات المطلوبة من Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
);

const LineChart = () => {
  // بيانات الرسم البياني
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        data: [30, 50, 40, 60, 70, 90, 45, 65, 75, 55, 80, 95], // بيانات لـ 12 شهرًا
        borderColor: "white",
        backgroundColor: "rgba(255, 143, 20, 0.2)",
        tension: 0.4,
        pointBackgroundColor: "#FF8F14",
        pointBorderColor: "white",
        pointRadius: 8,
        pointHoverRadius: 10,
      },
    ],
  };

  // خيارات الرسم البياني
  const options = {
    responsive: true,
    maintainAspectRatio: false, // يجعل الرسم البياني مرنًا
    plugins: {
      legend: {
        display: false, // إخفاء التوضيح (Legend)
      },
      tooltip: {
        enabled: true, // تمكين عرض المعلومات عند التمرير
        backgroundColor: "#FF8F14", // لون خلفية الأداة التوضيحية
        titleColor: "#fff",
        bodyColor: "#fff",
      },
    },
    scales: {
      x: {
        grid: {
          color: "rgba(255, 143, 20, 0.2)", // لون خطوط الشبكة المحورية
          drawBorder: false, // إخفاء الحدود المحورية
        },
        ticks: {
          color: "#FF8F14", // لون النصوص على المحور السيني
          font: {
            size: 14,
          },
        },
      },
      y: {
        grid: {
          color: "rgba(255, 143, 20, 0.2)", // لون خطوط الشبكة المحورية
          drawBorder: false, // إخفاء الحدود المحورية
        },
        ticks: {
          color: "#FF8F14", // لون النصوص على المحور الصادي
          font: {
            size: 14,
          },
        },
      },
    },
  };

  return (
    <Card
      sx={{
        width: "100%", // جعل العرض ديناميكيًا
        maxWidth: "none",
        height: "240px" ,
        margin: "auto",
        mt: 0,
        backgroundColor: "#202124",
        borderRadius: 4,
      }}
    >
      <CardContent sx={{ height: "100%" }}>
        <Line
          data={data}
          options={options}
          style={{ width: "100%", height: "100%" }} // ملء كامل الحاوية
        />
      </CardContent>
    </Card>
  );
};

export default LineChart;