import React, { useEffect, useState, useContext } from "react";
import { Chip, Avatar, Snackbar, Alert } from "@mui/material";
import axios from "axios";
import { AuthContext } from "./Authcontext";
import Loader from "./Loader";
import { API_URL } from "../confing";
import { useTranslation } from "react-i18next";


const Level = () => {
  const [levelsData, setLevelsData] = useState([]); // مصفوفة لتخزين بيانات المستويات
  const [loading, setLoading] = useState(true); // حالة التحميل
  const [error, setError] = useState(null); // رسالة الخطأ (إن وجدت)
  const [snackbarOpen, setSnackbarOpen] = useState(false); // حالة السناك بار
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();

  // دالة لتحميل البيانات
  const fetchData = async () => {
    setLoading(true); // بدء التحميل
    setError(null); // إعادة تعيين الخطأ عند بداية التحميل

    try {
      const response = await axios.get(`${API_URL}/api/users/levels/`, {
        headers: {
          Authorization: `Bearer ${token}`, // إضافة التوكن في رأس الطلب
        },
      });
      setLevelsData(response.data.data || []); // تخزين البيانات أو مصفوفة فارغة إذا كانت null
    } catch (err) {
      // في حالة الخطأ، ضبط رسالة الخطأ
      setError(err.response?.data?.message || "Failed to fetch data");
      setSnackbarOpen(true); // فتح السناك بار عند حدوث خطأ
    } finally {
      setLoading(false); // إيقاف حالة التحميل
    }
  };

  useEffect(() => {
    if (token) {
      fetchData(); // إذا كان التوكن موجودًا، قم بتحميل البيانات
    } else {
      console.error("Token is missing!"); // إذا كان التوكن مفقودًا، اعرض رسالة خطأ
    }
  }, [token]);

  // التحكم بإغلاق السناك بار
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  // إذا كانت الصفحة في حالة تحميل، إعرض Loader
  if (loading) {
    return (
      <div className="Levels">
        <Loader />
      </div>
  
  );
  }

  // إذا لم تكن هناك بيانات لعرضها، إعرض رسالة
  if (!levelsData.length) {
    return (
      <div className="Levels">
        <h1 className="levtit">{t('levels')}:</h1>
        <p>No levels available at the moment.</p>
      </div>
    );
  }

  return (
    <div className="Levels">
      <h1 className="levtit">Levels:</h1>
      <div className="levelitems">
        {levelsData.map((level, index) => (
          <div className="levelcont" key={index}>
            <div className="header1">
              <img
                src={require("../image/bronze.png")}
                height={"70px"}
                alt={level.title || "Level"}
              />
              <h1>{level.title || "No title available"}</h1>
            </div>
            <div className="levelcontent">
              <Chip
                avatar={
                  <Avatar
                    src={require("../image/increase.png")}
                    alt="Daily Profit"
                  />
                }
                label={`Daily profit: ${level.advantage_percentage || 0}$`}
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid #ff8000",
                  color: "#ff8000",
                  borderRadius: "8px",
                  marginBottom: "8px",
                }}
              />
              <Chip
                avatar={
                  <Avatar
                    src={require("../image/pay.png")}
                    alt="Subscription"
                  />
                }
                label={`Subscription value: ${level.user_amount || 0}$`}
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid #ff8000",
                  color: "#ff8000",
                  borderRadius: "8px",
                  marginBottom: "8px",
                }}
              />
              <Chip
                avatar={
                  <Avatar src={require("../image/join.png")} alt="Referrals" />
                }
                label={`Number of referred subscribers: ${
                  level.ref_visiting || 0
                }`}
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid #ff8000",
                  color: "#ff8000",
                  borderRadius: "8px",
                  marginBottom: "8px",
                }}
              />
              <Chip
                avatar={
                  <Avatar
                    src={require("../image/group.png")}
                    alt="Subscribers"
                  />
                }
                label={`Number of subscribers: ${level.subscribers || 0}`}
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid #ff8000",
                  color: "#ff8000",
                  borderRadius: "8px",
                  marginBottom: "8px",
                }}
              />
              <button className="levleBut">
                SUBSCRIBE NOW
                <img
                  src={require("../image/arrow.png")}
                  height={"40px"}
                  alt="Arrow"
                />
              </button>
            </div>
          </div>
        ))}
      </div>
      {/* عرض السناك بار عند حدوث خطأ */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error" onClose={handleCloseSnackbar}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Level;
