/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import axios from "axios";
import { API_URL } from "../confing";
import { AuthContext } from "./Authcontext";
import CircularProgress from "@mui/material/CircularProgress"; // لودر
import { useTranslation } from "react-i18next";

const SendWith = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState("");
  const [amount, setQuantity] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false); // حالة اللودر
  const [error, setError] = useState(null); // رسالة الخطأ
  const { token } = useContext(AuthContext);

  // فتح الـ Dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // إغلاق الـ Dialog
  const handleClose = () => {
    setOpen(false);
  };

  // إغلاق الـ Snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setError(null); // مسح أي رسالة خطأ عند إغلاق الـ Snackbar
  };

  // التعامل مع الإرسال
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // تحقق إذا كان المبلغ فارغًا
    if (!amount || amount <= 0) {
      setError("Amount must be greater than zero.");
      return;
    }
  
    const data = {
      amount,
    };
  
    setLoading(true); // تفعيل اللودر
    setError(null); // مسح أي رسالة خطأ
  
    try {
      const response = await axios.post(
        `${API_URL}/api/users/withdraws`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response:", response.data);
  
      setSnackbarOpen(true); // إظهار رسالة النجاح
      setDate("");
      setQuantity("");
      handleClose();
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred while submitting the data."); // إظهار رسالة الخطأ
    } finally {
      setLoading(false); // إيقاف اللودر
    }
  };

  return (
    <React.Fragment>
      <button onClick={handleClickOpen} className="mm">
        {t("withdrwamoney")}
        <img src={require("../image/money-withdrawal.png")} height={"30px"} alt="" />
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "#202124", // Background color
            color: "#ff8800", // Text color
          },
        }}
      >
        <DialogTitle style={{ color: "#ff8800" }}>{t("withdrwamoney")}</DialogTitle>
        <DialogContent>

          <TextField
            required
            margin="dense"
            id="amount"
            name="amount"
            label={`${t("ammount")}`}
            type="number"
            fullWidth
            variant="standard"
            InputLabelProps={{
              style: { color: "#ff8800" },
            }}
            InputProps={{
              style: { color: "#ff8800" },
            }}
            value={amount}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "#ff8800" }}>
          {t("cancle")}
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit}
            style={{ color: "#ff8800" }}
            disabled={loading} // تعطيل الزر أثناء تحميل البيانات
          >
            {loading ? (
              <CircularProgress size={24} style={{ color: "#ff8800" }} />
            ) : (
              t("senddata")
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar لرسائل الخطأ والنجاح */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          severity="success"
          variant="filled"
          style={{ backgroundColor: "#ff8800", color: "#202124" }}
        >
          Data submitted successfully!
        </Alert>
      </Snackbar>

      {/* Snackbar لرسالة الخطأ */}
      {error && (
        <Snackbar
          open={true}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="error"
            variant="filled"
            style={{ backgroundColor: "#d32f2f", color: "#fff" }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </React.Fragment>
  );
};

export default SendWith;
