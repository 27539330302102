import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Chip from "@mui/material/Chip"; // استيراد Chip من ماتريال UI
import axios from "axios";

import { AuthContext } from "./Authcontext";
import Loader from "./Loader";
import { API_URL } from "../confing";

const TableofCharge = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token } = useContext(AuthContext);

  // دالة لحذف العنصر
  const handleDelete = async (id) => {
    try {
      const response = await axios.request({
        method: "DELETE",
        url: `${API_URL}/api/users/charges`,
        data: { ids: [id] },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
        console.log(`Charge with ID: ${id} has been deleted.`);
      } else {
        console.error(`Failed to delete charge with ID: ${id}.`);
      }
    } catch (error) {
      console.error(`Error deleting charge with ID: ${id}`, error);
    }
  };

  // دالة لجلب البيانات
  const fetchCharges = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/users/charges/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Fetched Data:", response.data);
      const chargesData = response.data.data.map((charge) => ({
        id: charge.reference_number, // تعيين معرف فريد
        amount: charge.amount,
        date: charge.date,
        reference_number: charge.reference_number,
        status: charge.status,
      }));

      setRows(chargesData);
    } catch (error) {
      console.error("Error fetching charges:", error);
    } finally {
      setLoading(false);
    }
  };

  // جلب البيانات عند تحميل المكون
  useEffect(() => {
    if (token) {
      fetchCharges();
    }
  }, [token]);

  // تعريف الأعمدة
  const columns = [
    {
      field: "reference_number",
      headerName: "Reference Number",
      headerClassName: "super-app-theme--header",
      flex: 0.3,
    },
    {
      field: "amount",
      headerName: "Amount",
      headerClassName: "super-app-theme--header",
      flex: 0.3,
    },
    {
      field: "date",
      headerName: "Date",
      headerClassName: "super-app-theme--header",
      flex: 0.3,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      flex: 0.3,
      renderCell: (params) => (
        <Chip
          label={params.value || "Unknown"}
          style={{
            backgroundColor: "transparent",
            border: "1px solid #ff8000",
            color: "#ff8000",
            borderRadius: "8px",
          }}
        />
      ),
    },
    // {
    //   field: "delete",
    //   headerName: "Delete",
    //   headerClassName: "super-app-theme--header",
    //   flex: 0.1,
    //   sortable: false,
    //   renderCell: (params) => (
    //     <IconButton
    //       color="#ff8800"
    //       onClick={() => handleDelete(params.row.id)}
    //       sx={{
    //         alignItems: "center",
    //       }}
    //     >
    //       <DeleteIcon color="#ff8800"/>
    //     </IconButton>
    //   ),
    // },
  ];

  // عرض اللودر إذا كانت البيانات قيد التحميل
  if (loading) return <Loader />;

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "95%",
          textAlign: "center",
          overflow: "hidden",
          backgroundColor: "#202124",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Box
          sx={{ width: "100%", overflowX: "auto", backgroundColor: "#202124" }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 9,
                },
              },
            }}
            pageSizeOptions={[9]}
            disableRowSelectionOnClick
            sx={{
              minWidth: "800px",
              width: "100%",
              border: "none",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#202124",
                color: "#ff8800",
                fontSize: "15px",
                fontWeight: "500",
              },
              "& .MuiDataGrid-row": {
                backgroundColor: "#202124",
                color: "#ff8800",
                "&:hover": {
                  backgroundColor: "#F0F0F0",
                },
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "1px solid #202124",
              },
              "& .MuiCheckbox-root": {
                color: "#ff8800",
              },
              "& .super-app-theme--header": {
                backgroundColor: "#202124",
                color: "#ff8800",
              },
            }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default TableofCharge;
