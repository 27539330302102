/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useContext } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import axios from "axios";
import { API_URL } from "../confing";
import { AuthContext } from "./Authcontext";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { Chip } from "@mui/material";
const EditProfile = () => {
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [wallet, setWalletAddress] = useState("");
  const [network_number, setNetworkId] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [password, setPassword] = useState("");

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setError(null);
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_URL}/api/auth/`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": "en",
          },
        });
        setWalletAddress(response.data.wallet_address || "");
        setNetworkId(response.data.network_id || "");
      } catch (err) {
        console.error("Error fetching profile data:", err);
        setError(t("fetchProfileError"));
      } finally {
        setLoading(false);
      }
    };
    fetchProfileData();
  }, [token, t]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!wallet || !network_number || !password) {
      setSnackbarMessage(t("fieldsRequired"));
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    try {
      await axios.put(
        `${API_URL}/api/auth/`,
        { wallet: wallet, network_number: network_number, password: password },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSnackbarMessage(t("updateSuccess"));
      setSnackbarOpen(true);
      handleClose();
    } catch (err) {
      console.error("Error updating profile:", err);
      setError(t("updateError"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <button
        onClick={handleClickOpen}
        className="mm"
        style={{ color: "white", display: "flex", alignItems: "center" }}
      >
        Edit Profile
        <EditIcon style={{ marginRight: "5px", color: "white" }} />
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { backgroundColor: "#202124", color: "#ff8800" },
        }}
      >
        <DialogTitle style={{ color: "#ff8800" }}>Edit Profile</DialogTitle>
        <DialogContent>
          <TextField
            required
            margin="dense"
            id="walletAddress"
            name="walletAddress"
            label={t("walletAddress")}
            type="text"
            fullWidth
            variant="standard"
            InputLabelProps={{ style: { color: "#ff8800" } }}
            InputProps={{ style: { color: "#ff8800" } }}
            value={wallet}
            onChange={(e) => setWalletAddress(e.target.value)}
          />
          <TextField
            required
            margin="dense"
            id="networkId"
            name="network name"
            label={t("network name")}
            type="text"
            fullWidth
            variant="standard"
            InputLabelProps={{ style: { color: "#ff8800" } }}
            InputProps={{ style: { color: "#ff8800" } }}
            value={network_number}
            onChange={(e) => setNetworkId(e.target.value)}
          />
          <TextField
            required
            margin="dense"
            id="newpassword"
            name="newpassword"
            label={t("New Password")}
            type="text"
            fullWidth
            variant="standard"
            InputLabelProps={{ style: { color: "#ff8800" } }}
            InputProps={{ style: { color: "#ff8800" } }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "#ff8800" }}>
            {t("cancel")}
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit}
            style={{ color: "#ff8800" }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} style={{ color: "#ff8800" }} />
            ) : (
              t("saveChanges")
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          severity="success"
          variant="filled"
          style={{ backgroundColor: "#ff8800", color: "#202124" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {error && (
        <Snackbar
          open={true}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="error"
            variant="filled"
            style={{ backgroundColor: "#d32f2f", color: "#fff" }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </React.Fragment>
  );
};

export default EditProfile;
