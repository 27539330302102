import React, { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const QueryParamContext = createContext();

export const QueryParamProvider = ({ children }) => {
    const [queryParam, setQueryParam] = useState(null);
    const location = useLocation();

    const saveQueryParam = (paramValue) => {
        setQueryParam(paramValue);
        localStorage.setItem("queryParam", paramValue);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const paramValue = searchParams.get("token");

        if (paramValue) {
            saveQueryParam(paramValue);
        } else {
            const storedParam = localStorage.getItem("queryParam");
            if (storedParam) {
                setQueryParam(storedParam);
            }
        }
    }, [location.search]);

    return (
        <QueryParamContext.Provider value={{ queryParam, saveQueryParam }}>
            {children}
        </QueryParamContext.Provider>
    );
};
